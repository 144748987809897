<template>
  <div>
    <el-breadcrumb separator-class="el-icon-arrow-right">
       <el-breadcrumb-item :to="{ path: '/index' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item style="color: #fff">代驾管理</el-breadcrumb-item>
      <el-breadcrumb-item>计费设置</el-breadcrumb-item>
    </el-breadcrumb>
    
   <el-card style=" overflow: auto">
     <div slot="header" class="clearfix">
      <el-col class="mr15" :span="3.3" >
        <v-distpicker
               @city="oncity"
               class="v-distpicker"
               :province="queryInfo.province"
               :city="queryInfo.city"
               hide-area
             ></v-distpicker>
             </el-col>
      </div>
        <div class="hotKeyWords" >
        <div class="HeaderTitle" >
   
          <h4  style="margin:0px 20px 20px 0px">里程费设置</h4>
          <el-button v-if="MileagefeeData.length==0" type="primary" size="mini" @click="addmerchandiser=true">添加</el-button>
          <el-button  v-else type="primary" size="mini" @click="addmerchandiservisy()">编辑</el-button>
        </div>
        <el-table :data="MileagefeeData" style="width: 400px">
      
        <el-table-column prop="name" label="时间段" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
          <span>{{scope.row.startTime}}-{{scope.row.endTime}}</span>
             </template>
        </el-table-column>
        <el-table-column label="每公里价格" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
          <span>{{scope.row.money}}元</span>
        </template>
        </el-table-column>
        </el-table>
      </div>
      <el-divider><i class="el-icon-sunny"></i></el-divider>
      <div class="hotKeyWords" >
        <div class="HeaderTitle" >
          <h4  style="margin:0px 20px 20px 0px">起步价设置</h4>
          <el-button v-if="homeBannerData.length==0" type="primary" size="mini" @click="addmerstarting=true">添加</el-button>
          <el-button  v-else type="primary" size="mini" @click="addmerstartingvisy()">编辑</el-button>
        </div>
        <el-table :data="homeBannerData" style="width: 400px">
         
        <el-table-column prop="name" label="时间段" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
          <span>{{scope.row.startTime}}-{{scope.row.endTime}}</span>
             </template>
        </el-table-column>
        <el-table-column label="每公里价格" width="180" show-overflow-tooltip>
          <template slot-scope="scope">
          <span>{{scope.row.money}}元</span>
        </template>
        </el-table-column>
        </el-table>
      </div>
      <el-divider><i class="el-icon-sunny"></i></el-divider>
       <!--等待时间计费-->
      <div class="homeBanner">
        <div class="HeaderTitle">
          <h4>通用设置</h4>
        </div>
        <el-row style="margin-top: 18px;">
          <div style="font-size: 12px;" >
       
              <span style="padding-right:8px ;">司机路线规划策略</span>
              <el-select size="mini" v-model="strategy"  placeholder="司机路线规划策略" style=" width: 220px;"  @change="onstrategy"  class="mr15">
                <el-option
                  v-for="(item, index) in orderStatust"
                  :key="index"
                   :label="item.label"
                 :value="item.value"
                ></el-option>
              </el-select>
            
        </div>
        
        </el-row>
         <el-row style="margin-top: 18px;">
         <div style="font-size: 12px;" >
      <span style="padding-right:8px ;" >免费取消时间</span><el-input  size="mini"  style="width:80px;padding-right:8px ;" v-model="cancellationData.cancelFree"  @blur="freeblur" ></el-input><span  class="mr15 " style="font-size: 12px;"> 分钟</span>
  
      <span style="font-size: 12px;padding-right:8px ;">超出取消等待时间后/分钟 </span><el-input @blur="freeblur" class=".mr15" v-model="cancellationData.freeTime" size="mini"  style="width:80px;padding-right:8px ;"></el-input><span  style="font-size: 12px;">元</span>
      </div>
      </el-row>
      <el-row style="margin-top: 18px;">
        <div style="font-size: 12px;" >
        
     <span style="padding-right:8px ;">免费等待时间</span><el-input  size="mini" @blur="awaitblur"  style="width:80px;padding-right:8px ;" v-model="waitingcostData.freeWaitingMinute"></el-input><span  class="mr15 " style="font-size: 12px;"> 分钟</span>
 
     <span style="font-size: 12px;padding-right:8px ;">超出免费等待时间后/分钟 </span><el-input class=".mr15" @blur="awaitblur"  v-model="waitingcostData.money" size="mini"  style="width:80px;padding-right:8px ;"></el-input><span  style="font-size: 12px;">元</span>
     </div>
     </el-row>
     <el-row style="margin-top: 18px;">
      <div style="font-size: 12px;" >
      
   <span style="padding-right:8px ;">预支付大于</span><el-input  @blur="advanceblur" size="mini"  style="width:80px;padding-right:8px ;" v-model="advanceData.prepayFee"></el-input><span  class="mr15 " style="font-size: 12px;"> 元需要提前支付预付款</span>
   </div>
   </el-row>
   <el-row style="margin-top: 18px;">
    <div style="font-size: 12px;" >
    
 <span style="padding-right:8px ;">每笔订单扣除</span><el-input  size="mini"  style="width:80px;padding-right:8px ;" @blur="premiumblur"  v-model="insuranceData.fee"></el-input><span  class="mr15 " style="font-size: 12px;"> 元保险费</span>
 </div>
 
 </el-row>


      </div>
      <el-divider><i class="el-icon-sunny"></i></el-divider>
      <!--用户取消理由设置-->
     <div class="homeBanner">
       <div class="HeaderTitle">
         <h4>用户取消理由设置</h4>
       </div>
  <el-row style="margin-top: 18px;">
   <div style="font-size: 12px;" >
    <el-button  type="primary" size="mini" @click="usercancel.push({t1:''})">添加</el-button>
  </div>
  <div style="width: 700px;">
    <div v-for="(i,n) in usercancel " :key="i" >
      <el-input  size="mini"  style="width:480px; padding-top: 12px;   float: left;" @blur="pushdrivercancel" v-model="usercancel[n].t1"></el-input>
      <img style="width: 24px; margin:12px 0 0 12px; float: left;cursor:pointer" @click="remocancel(n)" src="../../assets/img/cancel-test.png" alt="">
      </div>
</div>
</el-row>
     </div>
     <el-divider><i class="el-icon-sunny"></i></el-divider>
     <!--用户取消理由设置-->
    <div class="homeBanner">
      <div class="HeaderTitle">
        <h4>司机取消理由设置</h4>
      </div>
 <el-row style="margin-top: 18px;">
  <div style="font-size: 12px;" >
   <el-button  type="primary" size="mini" @click="drivercancel.push({t1:''})">添加</el-button>
 </div>
 <div style="width: 700px;">
 <div v-for="(i,n) in drivercancel " :key="i" >
<el-input  size="mini"  style="width:480px; padding-top: 12px;   float: left;" @blur="pushdrivercancelse"  v-model="drivercancel[n].t1"></el-input>
<img style="width: 24px; margin:12px 0 0 12px; float: left;cursor:pointer" @click="remodrivercan(n)" src="../../assets/img/cancel-test.png" alt="">
</div>
</div>
</el-row>
    </div>
    <el-divider><i class="el-icon-sunny"></i></el-divider>
    <!--用户取消理由设置-->
   <div class="homeBanner">
     <div class="HeaderTitle">
       <h4>保险费联系电话</h4>
     </div>
<el-row style="margin-top: 18px;">
 <div style="font-size: 12px;" >
  <el-button  type="primary" size="mini" @click="insuranceinfo.push({t1:''})">添加</el-button>
</div>
<div style="width: 700px;">
  <div v-for="(i,n) in insuranceinfo " :key="i" >
    <el-input  size="mini"  style="width:480px; padding-top: 12px;   float: left;" @blur="puinsuranceinfo" v-model="insuranceinfo[n].t1"></el-input>
    <img style="width: 24px; margin:12px 0 0 12px; float: left;cursor:pointer" @click="remoinsuranceinfo(n)" src="../../assets/img/cancel-test.png" alt="">
    </div>
</div>
</el-row>
<!-- 添加里程费 -->
<el-dialog width="400px" :append-to-body="true" :visible.sync="addmerchandiser"  :lock-scroll="false" title="添加里程费">
<el-row>
  <div v-for="(i,n) in addMileagefee" :key="n" style="margin-top: 12px;">
  <span style="padding-right:8px ;">{{i.startTime}}-{{i.endTime}}</span><el-input  size="mini"  style="width:80px;padding-right:8px ;" v-model="i.money"></el-input><span  class="mr15 " style="font-size: 12px;"> 元</span>
</div>
</el-row>
<span slot="footer" class="dialog-footer">
  <el-button type="primary" @click="onaddMileagefee">确 定</el-button>
</span>
</el-dialog>
<!-- 编辑里程费 -->
<el-dialog width="400px" :append-to-body="true" :visible.sync="redactmerchandiser"  :lock-scroll="false" title="编辑里程费">
  <el-row>
    <div v-for="(i,n) in MileagefeeList" :key="n" style="margin-top: 12px;">
    <span style="padding-right:8px ;">{{i.startTime}}-{{i.endTime}}</span><el-input  size="mini"  style="width:80px;padding-right:8px ;" v-model="i.money"></el-input><span  class="mr15 " style="font-size: 12px;"> 元</span>
  </div>
  </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="onredactMileagefee">确 定</el-button>
  </span>
  </el-dialog>
  <!-- 添加起步价 -->
<el-dialog width="400px" :append-to-body="true" :visible.sync="addmerstarting"  :lock-scroll="false" title="添加起步价">
  <el-row>
    <div v-for="(i,n) in addstarting" :key="n" style="margin-top: 12px;">
    <span style="padding-right:8px ;">{{i.startTime}}-{{i.endTime}}</span><el-input  size="mini"  style="width:80px;padding-right:8px ;" v-model="i.money"></el-input><span  class="mr15 " style="font-size: 12px;"> 元</span>
  </div>
  </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="onaddstarting">确 定</el-button>
  </span>
  </el-dialog>
  <!-- 编辑起步价 -->
<el-dialog width="400px" :append-to-body="true" :visible.sync="redactmertarting"  :lock-scroll="false" title="编辑里程费">
  <el-row>
    <div v-for="(i,n) in inceptionList" :key="n" style="margin-top: 12px;">
    <span style="padding-right:8px ;">{{i.startTime}}-{{i.endTime}}</span><el-input  size="mini"  style="width:80px;padding-right:8px ;" v-model="i.money"></el-input><span  class="mr15 " style="font-size: 12px;"> 元</span>
  </div>
  </el-row>
  <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="onredactmertarting">确 定</el-button>
  </span>
  </el-dialog>
   </div>
    </el-card>
    
  </div>
</template>

<script>
// 引入拖动列表组件

import dataFunction from "@/utils/dataFunction";
import { searcDriverPayItemsList,searchDriverCommonList,insertDriverPayItems,updateDriverPayItems,updateDriverCommon } from "../../api/substitute";
import { FontClass } from "quill";

export default {
  name: "UsesignList",
  data() {
    return {
      insuranceinfoid:[],
      drivercancelid:"",
      usercancelid:"",
      strategyid:"",
      inceptionList:[],
      // 编辑弹窗
      redactmertarting:false,
      // 添加起步价数据
      addstarting:[
        {
        endTime: "07:00:00",
        mileage:3,
        money:"",
        startTime:"21:59:59"
      },
      {
        endTime: "22:00:00",
        mileage:3,
        money:"",
        startTime:"23:59:59"
      },
      {
        endTime: "00:00:00",
        mileage:3,
        money:"",
        startTime:"06:59:59"
      },
    ],
      // 起步价弹窗
      addmerstarting:false,
      // 
      MileagefeeList:[],
      redactmerchandiser:false,
      addMileagefee:[
        {
        endTime: "07:00:00",
        mileage:3,
        money:"",
        startTime:"21:59:59"
      },
      {
        endTime: "20:00:00",
        mileage:3,
        money:"",
        startTime:"22:59:59"
      },
      {
        endTime: "23:00:00",
        mileage:3,
        money:"",
        startTime:"23:59:59"
      },
      {
        endTime: "00:00:00",
        mileage:3,
        money:"",
        startTime:"06:59:59"
      },
    ],
      addmerchandiser:false,
      insuranceinfo:[],
      usercancel:[],
      drivercancel:[],
      strategy:"",
      CommonData:[],
      orderStatust:[  {
          value: 0,
          label: "速度优先(常规最快)",
        },
        {
          value: 1,
          label: "避免收费",
        },
         {
          value: 2,
          label: "距离优先",
        },
        {
          value: 3,
          label: "不走快速路",
        },
         {
          value: 4,
          label: "躲避拥堵",
        },
        {
          value: 5,
          label: "速度优先 & 费用优先 & 距离优先",
        },
         {
          value: 6,
          label: "不走高速",
        },
        {
          value: 7,
          label: "不走高速 & 避免收费",
        },
         {
          value: 8,
          label: "避免收费 & 躲避拥堵",
        },
        {
          value: 9,
          label: "不走高速 & 避免收费 & 躲避拥堵",
        },
      
        ],
      insuranceData:[],
      advanceData:[],
      waitingcostData:[],
      cancellationData:[],
      MileagefeeData:[],
      insurance:[],
      advance:[],
      homeBannerData:[],
      waitingcost:[],
      inception:[],
      cancellation:[],
      Mileagefee:[],
      gridData: [],
      // 弹窗页面设置
      setType: 0,
      // 新建区域弹窗
      innerVisible: false,
      form: {},
      fileList: [],
      outerVisible: false,
      // 查找商品列表参数对象
      queryInfo: {
        cityCode: "1522",
        province:"内蒙古自治区",
        city:"兴安盟",
      },
      total: 0,
      tableData: [],
    };
  },
  created() {
    this.getGoodsList();
    this.getchDriverCommonList()
  },
  methods: {
    // 删除客服电话
    remoinsuranceinfo(e){
      this.insuranceinfo.splice(e,1)
this.puinsuranceinfo()
    },
    // 编辑客服电话
    async  puinsuranceinfo(){
      const suranceinfolist=[]
      for (let i = 0; i < this.insuranceinfo.length; i++) {
        suranceinfolist.push(this.insuranceinfo[i].t1)
      }
      let data={
        content: JSON.stringify(suranceinfolist), 
        id: this.insuranceinfoid.id,
        type:"insurance_info"
}
  const { data: res } = await updateDriverCommon(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
    },
    // 删除司机理由
    remodrivercan(e){
      this.drivercancel.splice(e,1)
this.pushdrivercancelse()
    },
    // 编辑司机理由
    async pushdrivercancelse(){
      const drivercancelist=[]
      for (let i = 0; i < this.drivercancel.length; i++) {
       drivercancelist.push(this.drivercancel[i].t1)
      }
      let data={
        content: JSON.stringify(drivercancelist), 
        id: this.drivercancelid.id,
        type:"driver_cancel_reason_list"
}
  const { data: res } = await updateDriverCommon(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
    },
    // 删除用户取消理由
    remocancel(e){
      this.usercancel.splice(e,1)
this.pushdrivercancel()
    },
    // 编辑取消理由
    async pushdrivercancel(){
      const usercancelist=[]
      for (let i = 0; i < this.usercancel.length; i++) {
        usercancelist.push(this.usercancel[i].t1)
      }
      let data={
        content: JSON.stringify(usercancelist), 
        id: this.usercancelid.id,
        type:"user_cancel_reason_list"
}
  const { data: res } = await updateDriverCommon(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");

    },
    // 编辑路线策略
    async onstrategy(e){
      const type={
        type:e
      }
      let data={
        content: JSON.stringify(type), 
        id: this.strategyid.id,
        type:"navigate_strategy"
}
  const { data: res } = await   updateDriverCommon(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
     
    },
    // 编辑保险费
    async  premiumblur(){
      let data={
  "billingItemJson": JSON.stringify(this.insuranceData) ,
  "billingName":"保险费",
  "cityCode": 0,
  "cityName":"全国",
  "id": this.insurance.id,
  "type": 7
}
  const { data: res } = await  updateDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
    },
    // 编辑预支付
    async  advanceblur (){
      let data={
  "billingItemJson": JSON.stringify(this.advanceData) ,
  "billingName":"预付费",
  "cityCode": 0,
  "cityName":"全国",
  "id": this.advance.id,
  "type": 5
}
  const { data: res } = await  updateDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
    },
    // 编辑等待费
    async   awaitblur(){
      let data={
  "billingItemJson": JSON.stringify(this.waitingcostData) ,
  "billingName":"等待费",
  "cityCode": 0,
  "cityName":"全国",
  "id": this.waitingcost.id,
  "type": 4
}
  const { data: res } = await  updateDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
    },
    // 编辑免费取消时间
    async  freeblur(){
      let data={
  "billingItemJson": JSON.stringify(this.cancellationData) ,
  "billingName":"取消费",
  "cityCode": 0,
  "cityName":"全国",
  "id": this.cancellation.id,
  "type": 3
}
  const { data: res } = await  updateDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.$message.success("编辑成功");
    },
    // 确定编辑弹窗
    async   onredactmertarting(){
      let data={
  "billingItemJson": JSON.stringify(this.inceptionList) ,
  "billingName": "起步价",
  "cityCode": this.queryInfo.cityCode,
  "cityName": this.queryInfo.city,
  "id": this.inception.id,
  "type": 2
}
  const { data: res } = await  updateDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.getGoodsList()
  this.redactmertarting=false
  this.$message.success("编辑成功");
    },
    // 打开起步价编辑弹窗
    addmerstartingvisy(){
      const MileagefeeList =JSON.stringify(this.homeBannerData)
        this.inceptionList= JSON.parse(MileagefeeList)
      this.redactmertarting=true
    },
    // 添加起步价
    async onaddstarting(){
      let data={
  "billingItemJson": JSON.stringify(this.addstarting) ,
  "billingName": "起步价",
  "cityCode": this.queryInfo.cityCode,
  "cityName": this.queryInfo.city,
  "type": 2
}
  const { data: res } = await  insertDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("添加失败请稍后重试");
  this.getGoodsList()
  this.addmerstarting=false
  this.$message.success("添加成功");
    },
    // 打开里程费弹窗
    addmerchandiservisy(){
     const MileagefeeList =JSON.stringify(this.MileagefeeData)
        this.MileagefeeList= JSON.parse(MileagefeeList)
      this.redactmerchandiser=true
    },
  // 编辑里程
  async onredactMileagefee(){
    let data={
  "billingItemJson": JSON.stringify(this.MileagefeeList) ,
  "billingName": "里程费",
  "cityCode": this.queryInfo.cityCode,
  "cityName": this.queryInfo.city,
  "id": this.Mileagefee.id,
  "type": 1
}
  const { data: res } = await  updateDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("编辑失败请稍后重试");
  this.getGoodsList()
  this.redactmerchandiser=false
  this.$message.success("编辑成功");
  },
    // 添加里程
    async onaddMileagefee(){
let data={
  "billingItemJson": JSON.stringify(this.addMileagefee) ,
  "billingName": "里程费",
  "cityCode": this.queryInfo.cityCode,
  "cityName": this.queryInfo.city,
  "type": 1
}
  const { data: res } = await  insertDriverPayItems(data)
  if (res.code !== 200) return this.$message.error("添加失败请稍后重试");
  this.getGoodsList()
  this.addmerchandiser=false
  this.$message.success("添加成功");
    },
    showHomeBanner(){

    },
    // 通用字段列表
    async getchDriverCommonList(){
      const { data: res } = await searchDriverCommonList();
      if (res.code !== 200) return this.$message.error("代驾设置信息获取错误");
      this.CommonData= res.body.list
     for (let i = 0; i <this.CommonData.length; i++) {
if(this.CommonData[i].type=="navigate_strategy"){
  this.strategyid=this.CommonData[i]
  this.strategy=JSON.parse(this.CommonData[i].content).type
 
}else if(this.CommonData[i].type=="driver_cancel_reason_list"){
  this.drivercancelid=this.CommonData[i]
  const drivercancel=[]
  JSON.parse(this.CommonData[i].content).forEach(element => {
    console.log(element);
    drivercancel.push({'t1':element})
  });
  this.drivercancel=drivercancel
  
}else if(this.CommonData[i].type=="user_cancel_reason_list"){
  this.usercancelid=this.CommonData[i]
  const usercancel=[]
  JSON.parse(this.CommonData[i].content).forEach(element => {

    usercancel.push({'t1':element})
  });
  this.usercancel=usercancel
}else if(this.CommonData[i].type=="insurance_info"){
  this.insuranceinfoid=this.CommonData[i]
  const insurancein=[]
  JSON.parse(this.CommonData[i].content).forEach(element => {

    insurancein.push({'t1':element})
});
  this.insuranceinfo=insurancein
}

     }
    },
    getStoreList(){

    },
    /*
     * 价格设置列表
     * */
    async getGoodsList() {
   
  
        const { data: res } = await searcDriverPayItemsList(this.queryInfo);
        if (res.code !== 200) return this.$message.error("代驾设置信息获取错误");
        for (let i = 0; i < res.body.list.length; i++) {
          if(1==res.body.list[i].type){
        this.Mileagefee=res.body.list[i]
        this.MileagefeeData=JSON.parse(this.Mileagefee.billingItemJson)
     
        }else if(2==res.body.list[i].type){
          this.inception=res.body.list[i]
          this.homeBannerData=JSON.parse(this.inception.billingItemJson)
        }else if(3==res.body.list[i].type){
          this.cancellation=res.body.list[i]
  
          this.cancellationData=JSON.parse(this.cancellation.billingItemJson)
         
        }else if(4==res.body.list[i].type){
          this.waitingcost=res.body.list[i]
          this.waitingcostData=JSON.parse(this.waitingcost.billingItemJson)
       
         
       
        }else if(5==res.body.list[i].type){
          this.advance=res.body.list[i]
          this.advanceData=JSON.parse(this.advance.billingItemJson)
          
          
        }else if(7==res.body.list[i].type){
          this.insurance=res.body.list[i]
          this.insuranceData=JSON.parse(this.insurance.billingItemJson)
         
          
        }
       this.total = res.total;
      }
    },

    handleCurrentChange(data) {
      this.queryInfo.pageNum = data;
      this.getGoodsList();
    },
    // 省份城市搜索
    handleSizeChange(newSize) {
      this.queryInfo.pageSize = newSize;
      this.getGoodsList();
    },
    // 日期搜索
    dateHandle() {
      this.queryInfo.pageNum = 1;
      this.queryInfo.time = dataFunction(this.time);
      this.getGoodsList();
    },
    //选择省
    onprovince(data) {
      this.queryInfo.pageNum = 1;
      this.queryInfo.province = data.value;
      this.getGoodsList();
    },
    // 选择市
    oncity(data) {
      if(data.code){
        this.Mileagefee=[]
        this.MileagefeeData=[]
        this.inception=[]
        this.homeBannerData=[]
        this.cancellation=[]
        this.cancellationData=[]
        this.waitingcost=[]
        this.waitingcostData=[]
        this.advance=[]
        this.advanceData=[]
        this.insurance=[]
        this.insuranceData=[]
     this.queryInfo.cityCode= data.code.substring(0,4)
      this.queryInfo.city = data.value;
      }else{
         this.queryInfo.cityCode= ""
      this.queryInfo.city =""
      }
      this.getGoodsList();
    },
    // 退出
    logout() {
      window.sessionStorage.clear();
      this.$router.push("/login");
    },
    // 返回首页
    backHome() {
      this.$router.push("/index");
      window.sessionStorage.removeItem("activePath");
    },
  },
};
</script>

<style scoped lang="scss">
  .v-distpicker ::v-deep select {
    height: 29px !important;
  
    padding-top: 0.4rem;
    font-size: 12px;
  }
.mr15 {
  margin-right: 18px;
}

p {
  margin: 0;
  padding: 0;
}

ol,
ul {
  margin: 0;
  padding: 0;
}

.content ul {
  list-style: none;

  li {
    display: flex;
    align-items: center;
    margin-top: 25px;

    span {
      font-weight: bold;
      display: block;
      width: 120px;
    }

    img {
      display: block;

      width: 100px;
    }
  }
}


.CheckInTimeTitle {
  font-size: 12px;
  color: #606266;
  margin-right: 5px;
}
.Price {
  display: flex;
  flex-wrap: wrap;
}

.resetInputSize {
  width: 100px;
}

.quill-editor {
  line-height: normal;
}

.goodDesc p img {
  width: 100px;
}
.el-buttonr {
  width: 70px;
  height: 25px;
}
.goods-details {
  .el-row {
    margin-bottom: 10px;
  }

  .goodDesc {
    p {
      img {
        width: 100px !important;
      }
    }
  }
}

.el-tag + .el-tag {
  margin-left: 10px;
}

.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}

.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
.el-icon-s-fold,
.el-icon-s-unfold {
  font-size: 35px;
  margin: 22px;
  color: #999;
}
</style>